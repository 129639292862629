.fixedWidthCol {
  flex: 0 0 auto;
  width: 80px;
}

.marginT {
  margin-top: 30px;
}

.fixedCol-2 {
  width: 250px;
  position: relative;
}

.fixedCol-3 {
  flex: 0 0 auto;
  width: 450px;
}

.viewButton {
  background-color: #5746ec;
  color: #fff;
  font-weight: 500;
  border: 1px solid #5746eb;
}

.viewBttnExpert {
  background-color: transparent;
  color: #5746ec;
  font-weight: 400;
  width: 27px;
  height: 27px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #5746ec;
  border-radius: 5px;
  font-size: 15px;
}

.viewButton:hover {
  background-color: transparent;
  border-color: #5746ec !important;
  color: #5746ec !important;
}

.viewBttnExpert:hover {
  background-color: #5746ec;
  border-color: #5746ec !important;
  color: #fff !important;
}

.viewBttnExpert:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cust_tag {
  background-color: transparent !important;
  /* color: #5746ec; */
  font-weight: 400;
  font-size: small;
  text-align: center;
  padding: 0px 4px;
  width: auto;
  /* border: 1px solid #5746ec; */
}

.expertActions a {
  padding-right: 5px;
}

.expertActions a:last-child {
  padding-right: 0;
}

.fixedCol-2:after {
  content: "";
  height: 80%;
  background-color: #d3d3d3;
  width: 1px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.companyName {
  margin-top: 0px;
}

.linkedinBody {
  max-width: 100%;
}

.expertEditProfile h5 {
  font-weight: 600;
  font-size: 26px;
}

.ant-select-dropdown {
  z-index: 1100 !important;
}

.ant-form-item-label {
  font-weight: 500;
}

.closeIcons {
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.bckIcon:hover {
  color: #5746ec;
}

.paymentIconSize {
  font-size: 65px;
}

.paymentTitle {
  margin: 5px 0;
  font-weight: 500;
  font-size: 18px;
}

.paymentSubTitle {
  font-size: 12px;
}

.expertBox {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.tableIcon {
  font-size: 45px;
}

.themedChecked.ant-switch-checked {
  background-color: #5746eb;
}

.giveMarginBottom {
  border-bottom: 2px solid grey;
  margin-bottom: 40px;
}

.signedPDF {
}

.signedPDF h3 {
  font-size: 22px;
  font-weight: 500;
}

.signedPDF h4 {
  font-size: 18px;
  font-weight: 500;
}

.signedPDF p {
  font-size: 15px !important;
  font-weight: 300;
  margin-bottom: 1rem;
}

.signedPDF p span {
  font-weight: 600;
}

.listType {
  list-style-type: disc !important;
  padding-left: 16px !important;
}

.listType li {
  list-style-type: disc !important;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 1rem;
}

.text-wrap-afte-three-line {
  overflow: hidden;
  /* width:100px; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-wrap-after-four-line {
  overflow: hidden;
  /* width:100px; */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.fixedCol-lg-2 {
  flex: 0 0 auto;
  width: 120px;
}

.projectsList {
  background-color: #e7e7e7;
  padding: 2px;
}

.projectTitle {
  font-weight: 500;
  color: #5746eb;
  margin: 0;
  font-size: 14px;
}

.team-info {
  display: flex;
  align-items: center;
}

.team-info li {
  margin-left: -8px;
}

.team-info li:first-child {
  margin-left: 0px;
}
.team-info li img {
  width: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

.modalHeader {
  color: #5746eb;
  font: 18px;
  font-weight: 500;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown {
  z-index: 1200;
}

.projectEditor .ck-editor__editable {
  min-height: 200px;
}

.secondaryBttn {
  background-color: transparent;
  color: #5746eb;
  border: 1px solid #5746eb;
  font-weight: 500;
}

.projectOverview {
}

.projectOverview h4 {
  font-size: 18px;
  font-weight: 500;
}

.projectOverview .projectOl {
  padding-left: 16px;
}

.projectOverview .projectOl li {
}

.projectOverview .addExpertCard {
  border: 1px dashed gray;
}

.projectOverview .addExpertCard img {
  width: 200px;
}

.badge-success {
  border-color: #5746eb;
  color: #5746eb;
}

.badge {
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 0.7142em;
  line-height: 12px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid;
  margin-bottom: 5px;
  border-radius: 0.875rem;
}

.badge-success:hover {
  background-color: #5746eb;
  color: #fff;
}

.projectOverview .ant-card {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 16px;
}

.follow_us .media .media-object {
  width: 40px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

.client-teams-ul-li .list-unstyled li {
  margin-bottom: 15px;
}

.client-teams-ul-li .list-unstyled li:last-child {
  margin-bottom: 0;
}

.ckeditor_ProjectDetails .ck-editor__editable {
  height: 110px;
}

.expertTypes {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #80aaff;
  position: absolute;
  top: 120px;
  right: 16px;
  border: 3px solid #fff;
}

.Self.expertTypes {
  background-color: #22ffab;
}

.Consulting.expertTypes {
  background-color: #80aaff;
}

.projectEditor li {
  font-size: 1rem;
}

.projectEditor p {
  margin-bottom: 1rem;
}

.expert_Card div {
  display: inline-block;
  margin-bottom: 5px;
}

.ant-notification-notice-message,
.ant-notification-notice-close {
  color: #fff !important;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.ant-notification-notice-close {
  top: 9px !important;
  right: 9px !important;
}

.figureWrp {
  column-count: 2;
  column-gap: 10px;
}

.figureWrp .figureChld {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

.figureWrp .figureChld .sdwFi {
  grid-row: 1 / -1;
  grid-column: 1;
}

.ant-pagination-item-active,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #5746eb !important;
  background-color: #5746eb !important;
  color: #fff !important;
}

.notifications {
  margin-top: 30px;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
}

.notifications .notificationDiv {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 16px;
  padding-top: 16px;
}

.notifications .notificationDiv:first-child {
  padding-top: 0;
}

.notifications .notificationDiv:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.notificationFrom p {
  color: "#848594";
  font-size: 14px;
}

.ant-notification-notice-message,
.ant-notification-close-icon {
  color: #fff;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.ant-notification-notice-close {
  top: 9px !important;
  right: 9px !important;
}

.ant-pagination-total-text {
  float: left;
}

.cs_orange {
  line-height: unset;
  border-color: #ff7a00 !important;
  color: #ff7a00 !important;
}

.cs_green {
  line-height: unset;
  border-color: #389e0d !important;
  color: #389e0d !important;
}

.cs_red {
  line-height: unset;
  border-color: #c34a2c !important;
  color: #c34a2c !important;
}

.cs_blue {
  /* display: flex; */
  line-height: unset;
  border-color: #2f539b !important;
  color: #2f539b !important;
}

.cs_purple {
  /* display: flex; */
  line-height: unset;
  border-color: #ff66c4 !important;
  color: #ff66c4 !important;
}
