a {
  text-decoration: none !important;
}

.findExperts.comon-divs-re {
  width: 100%;
  padding-right: 20px;
}

.expertAction {
  margin-top: 10px;
}

.expertAction .edit_expert {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-weight: normal;
}

.findExperts.comon-divs-re-2 {
  width: 100%;
  padding-right: 100px !important;
}

.findExperts.comon-divs-re .linkH5 {
  width: 100%;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-item:focus,
.dropdown-item:active {
  background-color: #e9ecef !important;
}

.coverInnerBreadcum {
  display: flex;
  width: 70%;
}

.coverInnerBreadcum h3 {
  font-size: 20px;
  margin-bottom: 0;
}

.coverInnerBreadcumH3:hover {
  color: #5746ec;
  cursor: pointer;
}

.offcanvas-header .btn-close {
  margin: unset !important;
}

.width20 {
  width: 20px;
  height: 20px;
}

.findExperts .next-list li {
  margin-right: 6px;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-neutral {
  border-color: gray;
  color: gray !important;
}

.find-expert .table {
  min-width: 100%;
  max-width: 1500px;
  border-collapse: separate;
  border-spacing: 0px;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #d7d4d4;
}

.table > :not(caption) > * > * {
  border-bottom-width: 10px !important;
  border-bottom-color: #f8f8f8 !important;
}

.table tr th {
  /* border-top: 1px solid #d7d4d4; */
  border-bottom: 1px solid #d7d4d4;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
}

.table tr td {
  vertical-align: middle;
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.table tr td:first-child {
  padding-left: 30px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 20px;
}

.table tr th:first-child {
  padding-left: 30px;
}

.table tr td:last-child {
  padding-right: 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 20px;
}

.table tr th:last-child {
  padding-right: 30px;
}

.table tr td .projectTitle {
  margin-top: 0;
  margin-bottom: 7px;
  font-weight: 300;
  font-size: 18px;
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.table tr td .createdDate {
  color: #5746ec;
  font-size: 13px;
  font-weight: 300;
}

.table tr td ul {
  padding-bottom: 8px;
  margin-bottom: 0;
}

.table tr td p {
  margin-bottom: 0;
}

.table .number-member h6 {
  font-weight: 400;
}

.table tr td .dropdown img {
  width: 3px;
}

.notesParent .comon-notes {
  padding: 15px;
  height: 25.5rem;
}

.notesParent .paragraph {
  height: 207px;
  font-size: 14px;
  margin-top: 20px;
  overflow: hidden;
}

.notesParent .createdByName,
.folderSection .createdByName {
  color: #5746ec;
  text-transform: capitalize;
}

.notesParent .note-titel,
.folderSection .note-titel {
  height: 55px;
  margin-bottom: 0;
  font-size: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 28px;
}

.folderSection .paragraph {
  height: 200px;
  margin-top: 10px;
}

.commentsT {
  width: 35px;
  height: 35px;
  background-color: #f33636;
  border-radius: 50%;
  position: relative;
}

.commentsT span {
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
  top: 50%;
  transform: translate(0px, -50%);
}

.actionNav {
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
}

.actionNav img {
  width: 18px;
}

.linkH5 {
  cursor: pointer;
  font-size: 16px !important;
  line-height: 22px;
}

.findExperts .expertRate {
  font-size: 24px;
}

.linkH5:hover {
  color: #5746ec;
}

.projectTitle {
  cursor: pointer;
}

.projectTitle:hover {
  color: #5746ec;
}

.mainComment {
  background-color: #fff;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* overflow: auto; */
  height: calc(100vh - 330px);
  margin-bottom: 0;
}

.mainComment .adminMessage {
  width: 100%;
}

.messageScreen {
  position: relative;
  height: 100vh;
}

.mainComment .adminMessage .sdee {
  margin-left: 14px;
  width: 70%;
  position: relative;
  margin-bottom: 20px;
  float: left;
}

.mainComment .adminMessage .msgContent img {
  position: absolute;
  left: 0;
  width: 35px;
  top: 0px;
}

.mainComment .adminMessage .msgContent .markup-para {
  margin-left: 50px;
  text-align: justify;
  background-color: #a4a5a929;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

.mainComment a {
  color: #5746ec;
  text-decoration: none;
}

.mainComment .adminMessage .msgContent p {
  margin-bottom: 0;
}

.mainComment .adminMessage small {
  margin-left: 50px;
}

.mainComment .clientMessage {
  width: 100%;
}

.mainComment .clientMessage .sdee {
  position: relative;
  grid-template: 239px;
  width: 70%;
  float: right;
  margin-bottom: 20px;
}

.mainComment .clientMessage .msgContent .senderName {
  position: absolute;
  right: 20px;
  width: 35px;
  top: 0px;
}

.mainComment .clientMessage .msgContent .SentByClient {
  margin-right: 70px;
  text-align: justify;
  background-color: #c40a360f;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
  display: block;
}

.mainComment .clientMessage .msgContent p {
  margin-bottom: 0;
}

.modal-title h4 {
  font-size: 1.25rem;
}

/* .modal-body{
    padding-bottom: 0 !important;
} */

.modal-body p {
  font-size: 1rem !important;
}

/* Share Modal Css */

.modalWrapper {
  background: #ededf0;
  border-radius: 4px;
  color: #000;
  padding: 12px 16px;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkChild {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
}

.shareableLink {
  width: 100%;
  height: 42px;
  border: 1px solid #d7d4d4;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 5px;
}

.inviteSection .inviteTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.inviteSection .inputGroups {
  background: #fff !important;
  border: none;
  padding: 0;
}

.inviteSection .dropdownBtn {
  background: transparent;
  border: none;
  color: #000;
  font-size: 14px;
  padding: 0 20px;
}

.inviteSection .dropdownBtn:active,
.inviteSection .dropdownBtn:focus,
.inviteSection .dropdownBtn:hover {
  background: transparent;
  color: #000;
}

.inviteSection .dropdown-item {
  color: #000;
  font-size: 14px;
}

.inviteSection .dropdownBtn:after {
  margin-left: 1.255em;
}

.inviteSection .inputGroupWrppr {
  width: 100%;
  height: 42px;
  border: 1px solid #d7d4d4;
  font-size: 14px;
  border-radius: 5px;
}

.inviteSection .inputGroupInput {
  height: 40px;
  border: unset;
  font-size: 14px;
}

.accessInfo {
  display: flex;
  border: none;
  background: transparent;
  font-size: 15px;
  align-items: center;
  padding: 8px 0;
  width: 100%;
  border-bottom: 1px solid #d7d4d4;
}

.sharedInfoWrapper .nameInfo {
  font-size: 15px;
  font-weight: 500;
}

.sharedInfoWrapper .emailInfo {
  font-size: 13px;
  color: #5c5f62;
}

.sharedInfoWrapper .profileIconWrppr {
  display: flex;
  align-items: center;
}

.sharedInfoWrapper .profileIcon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-size: 40px;
  background-position: center;
}

.sharedInfoWrapper .shareWrppr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #d7d4d4;
}

/* .Yourr_9w71MjtL3S9I4G .dropdownBtn,
.Yourr_9w71MjtL3S9I4G .dropdownBtn:active,
.Yourr_9w71MjtL3S9I4G .dropdownBtn:focus,
.Yourr_9w71MjtL3S9I4G .dropdownBtn:hover,
.show .btn-primary {
  background-color: transparent !important;
  border: none !important;
  color: #000 !important;
} */

.Yourr_9w71MjtL3S9I4G .dropdown-toggle::after {
  position: relative;
  top: 3px;
}

.Yourr_9w71MjtL3S9I4G .dropdown-item {
  font-size: 15px;
}

.ZP9BDnxHhGA9rSLoFsa3 {
  padding: 10px;
  margin-bottom: -14px;
  text-align: right;
}

.ZP9BDnxHhGA9rSLoFsa3 .cDCnsp1vcViX1pVJM7MV {
  background: transparent;
  border: none;
  color: #5746ec;
}

.findExperts .find_companyName {
  font-size: 19px;
  width: 100%;
  font-weight: 500;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize; */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.noResultFound {
  max-width: 700px;
  margin: auto;
}

.browseTopRated {
  font-size: 18px;
  font-weight: 300;
}

.number-list {
  padding-top: 6px;
}

.messageEditorWrapper {
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: 10px;
  width: 94.5%;
}

.offcanvas-body {
  padding: 0rem 2rem !important;
}

.offcanvas-header {
  padding: 1rem 2rem 1rem 1.5rem !important;
}

.menu-offCanvas {
  padding: 0 10px !important;
}

.topmenu1 {
  width: 100%;
  position: relative;
  display: block;
}

.rangeSliderRefinement {
  height: 200px !important;
}

[class*="sendbird-label"] {
  font-family: "Work Sans", sans-serif !important;
}

.sendbird-theme--light .sendbird-text-message-item-body.outgoing {
  background-color: #c40a360f !important;
  color: #000;
  line-height: 25px;
}

.sendbird-label--h-2 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.sendbird-label--button-1,
.sendbird-label--subtitle-2 {
  font-weight: 500 !important;
}

.sendbird-label--subtitle-1 {
  font-size: 14px !important;
}

.sendbird-badge .sendbird-badge__text {
  margin: -3px 6px;
}

.sendbird-icon-toggle-off svg .icon-toggleoff_svg__fill {
  fill: #e0e0e0 !important;
}

.sendbird-icon-toggle-off .icon-toggleoff_svg__fill {
  fill: #e0e0e0 !important;
}

.sendbird-channel-settings__accordion-icon svg,
.sendbird-channel-settings__panel-icon-left svg {
  height: 80% !important;
}

.sendbird-chat-header {
  background-color: #ededf0 !important;
  height: 55px !important;
}

.sendbird-channel-preview {
  margin-top: 12px !important;
  height: 64px !important;
  box-shadow: none !important;
  padding: 12px 12px 12px 7px !important;
  border: none !important;
}

.sendbird-channel-preview__content__upper {
  width: unset !important;
}

.sendbird-channel-header__title__left {
  padding: 4px 0 !important;
}

.sendbird-channel-header__title:hover {
  background-color: transparent !important;
}

.sendbird-avatar-img,
.sendbird-avatar,
.sendbird-avatar-img--default,
.sendbird-channel-preview .sendbird-channel-preview__avatar,
.sendbird-avatar-img .sendbird-image-renderer__image {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  min-width: 40px !important;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower {
  margin-top: 4px !important;
}

.sendbird-theme--light .sendbird-avatar .sendbird-avatar-img {
  background-color: transparent !important;
}

.sendbird-channel-preview__content {
  margin-left: 8px !important;
}

.sendbird-text-message-item-body__message.edited {
  color: darkgray !important;
}

.sendbird-channel-preview--active {
  border: 1px solid #5746ec !important;
}

.sendbird-conversation__footer {
  padding: 10px 0px 0px 0px !important;
}

.sendbird-theme--light .sendbird-chat-header {
  box-shadow: unset !important;
}

.sendbird-place-holder,
.sendbird-conversation__messages .sendbird-conversation__messages-padding {
  background-color: #fff !important;
  margin-top: 10px !important;
}

.sendbird-button--danger,
.sendbird-button--primary {
  background-color: #5746ec !important;
  border-color: #5746ec !important;
}

.sendbird-icon-color--primary svg path {
  fill: #5746ec !important;
}

.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:focus {
  border: solid 1px #5746ec !important;
}

.sendbird-message-input__edit {
  background-color: #c40a360f !important;
}

.body-expart-div,
.select-option,
.customized-app {
  padding-left: 40px;
  padding-right: 40px;
}

.select-option .card-dropdown {
  width: 100%;
}

.createNotesEditor {
  position: relative;
}

.createNotesEditor .jodit-toolbar__box:not(:empty) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  top: unset;
  z-index: 9;
}

.createNotesEditor .jodit-container:not(.jodit_inline) {
  border: none;
}

.createNotesEditor .jodit-status-bar {
  display: none;
}

.createNotesEditor .jodit-wysiwyg_mode,
.createNotesEditor .jodit-workplace {
  min-height: calc(100vh - 480px) !important;
}

.createNotesEditor .jodit-react-container {
  background-color: #ededf0;
  border-radius: 10px;
  padding: 10px;
}

.jodit-toolbar-editor-collection_mode_horizontal:after {
  background-color: transparent !important;
}

.nav-pills li {
  padding: 3px 1.5px;
}

.nav-pills li:first-child {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 1.5px;
  padding-left: 3px;
}

.nav-pills li:last-child {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 1.5px;
  padding-right: 3px;
}

.sendbird-place-holder {
  height: calc(100% - 230px) !important;
}

.ais-SearchBox-input {
  box-shadow: none;
  border: none;
}

.stylishEditor {
  border-radius: 10px;
  padding-bottom: 9px;
}

.commentTable .tableHe {
  color: grey;
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-block;
  width: 50%;
}

.commentTable .tableCo {
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-block;
  color: #000;
  width: 50%;
}

.commentBody .userImage {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.createNotesEditor.commentSection .jodit-wysiwyg_mode,
.createNotesEditor.commentSection .jodit-workplace {
  min-height: calc(100vh - 680px) !important;
}

.locationSpeed span {
  text-transform: capitalize;
}

.transcriptNew {
  border-radius: 15px;
  background-color: #fff;
}

.transcriptNew:hover {
  background-color: #fff;
}

.transcriptNew .fileIcons {
  color: #8294a5;
  font-size: 35px;
  cursor: pointer;
}

.transcriptNew .pContent {
  cursor: pointer;
}

.transcriptNew .pContent p {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  color: #000;
}

.transcriptNew .pContent li {
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: #000;
  text-transform: uppercase;
}

.transcriptNew .moreIcon {
  font-size: 20px;
  cursor: pointer;
}

.moreOptions {
  background-color: transparent !important;
  color: #000;
  border: none !important;
  font-size: 25px;
  padding: 0;
}

.moreOptions svg {
  width: 1em !important;
  height: 1em !important;
}

.moreOptions:hover,
.moreOptions:focus,
.moreOptions:active {
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  color: #000 !important;
}

.moreOptions:hover .moreIcon {
  color: #000;
}

.moreOptions:after {
  content: unset;
}

.form-group .css-b62m3t-container {
  width: 80%;
}

.sendBttnMessage {
  position: absolute;
  z-index: 9;
  font-size: 20px;
  height: 30px;
  border-radius: 50px;
  width: 30px;
  border: none;
  background: #5746ec;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 10px;
}

.createBttnNotes {
  position: absolute;
  top: 5px;
  right: 18px;
  z-index: 9;
  width: 120px;
}

.createBttnNotes button {
  width: 100%;
}

.speakerName {
  position: absolute;
  width: 35px;
  height: 35px;
  color: maroon;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 400;
}

.successModal {
  width: 100%;
  background-color: rgb(87, 70, 236);
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  display: block;
  padding: 8px;
  border-radius: 30px;
  margin-top: 50px;
}

.error.successModal {
  background-color: #df1e1e;
}

.successModal:hover {
  color: #fff;
}

.projectOverView p {
  font-weight: 300;
}

.projectOverView strong {
  font-weight: 500;
}

.projectOverView ul,
.projectOverView ol,
.ck-editor__editable ul,
.ck-editor__editable ol {
  padding-left: 30px;
}

.projectOverView ol li,
.projectOverView ul li,
.ck-editor__editable ul li,
.ck-editor__editable ol li {
  margin-top: 5px;
  margin-bottom: 10px;
}

.projectOverView ol li,
.ck-editor__editable ol li {
  list-style-type: numbers;
}

.projectOverView ul li,
.ck-editor__editable ul li {
  list-style-type: disc !important;
}

.ck-editor__editable strong {
  font-weight: bolder !important;
}

.chatWithUs {
  position: fixed;
  bottom: 20px;
  right: 100px;
}

.chatWithUs img {
  height: 70px;
  text-align: right;
  margin-right: auto;
  transform: scaleX(-1) rotate(90deg) translate(0px, 100px);
}

.csvlink {
  color: white;
}

.css-1py0z5p {
  height: calc(100vh - 360px) !important;
}

.antDBttn {
  background-color: #5746ec;
  color: #fff;
}

.antDBttn:hover {
  background-color: transparent;
  color: #5746ec;
}

.removeScrollbar::-webkit-scrollbar,
.removeScrollbar .ant-modal-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.removeScrollbar,
.removeScrollbar .ant-modal-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-input-lg {
  font-size: 14px !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-input-lg {
  padding: 9px 11px !important;
}

.ant-input-affix-wrapper .ant-input-lg {
  padding: unset !important;
}

.ant-picker-large {
  padding: 9px 11px 9px !important;
}

.ant-picker-input input,
.ant-input-number-input {
  font-size: 14px !important;
}

.sdwFi .addexpert-section-divs2 {
  padding: 20px !important;
}

.attachment_overview {
  border: 1px solid #696969;
  width: fit-content;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 20px;
  margin-bottom: 5px;
  cursor: pointer;
}

.attachment_overview a {
  color: #696969 !important;
}

.attachment_overview:hover {
  border: 1px solid #5746ec;
}

.attachment_overview:hover a,
.attachment_overview:hover svg {
  color: #5746ec !important;
}

.notify_bubble {
  height: 16px;
  width: 16px;
  background: rgb(76, 187, 23);
  position: absolute;
  border-radius: 50%;
  bottom: 17px;
  left: 18px;
  font-size: 10px;
  color: white;
  text-align: center;
  padding: 1px;
}
.notify_bubble_inner {
  height: 14px;
  width: 14px;
  background: rgb(76, 187, 23);
  position: absolute;
  border-radius: 50%;
  bottom: 13px;
  left: 13px;
  font-size: 10px;
  color: white;
  text-align: center;
  padding: 1px;
}

/* Added from AddProjectModal.css  */
.ck-balloon-panel {
  z-index: 9999 !important;
}

/* Added from CallRecords.css */
.avtrgrp div {
  position: relative !important;
  z-index: 9 !important;
}

/* Added from CallRecords.css */
.input-select {
  width: 300px;
}
/* from callRecords for all the tables */
.ant-table-cell {
  vertical-align: middle !important;
  white-space: nowrap !important;
  /* padding: 20px !important; */
  background: #fff !important;
}

/* from callRecords for all the tables */
.custom_table_cell .ant-table-cell {
  vertical-align: middle !important;
  white-space: nowrap !important;
  /* padding: 20px !important; */
  background: #fff !important;
}
.icnvtbd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  color: #000;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 15px;
}

.icnvtbd:hover {
  background-color: #5746ec;
  color: #fff;
}
#termsAndConditions .mainheading {
  font-size: 30px;
  font-family: "Times New Roman", Times, serif;
}

#termsAndConditions h4 {
  font-size: 24px;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
}

#termsAndConditions p,
#termsAndConditions a,
#termsAndConditions span {
  font-size: 22px;
  font-weight: 300;
  line-height: 35px;
  font-family: "Times New Roman", Times, serif;
}

#termsAndConditions ul {
  padding-left: 15px;
  font-family: "Times New Roman", Times, serif;
}

#termsAndConditions ul li {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 10px;
  list-style-type: disc;
  line-height: 35px;
  font-family: "Times New Roman", Times, serif;
}

#generatedPDF {
  page-break-inside: avoid;
}

.page-break {
  page-break-before: always;
  break-before: always;
  height: 12px;
}

.cedarville-cursive-regular {
  font-family: "Cedarville Cursive", serif !important;
  font-weight: 400;
  font-style: normal;
}

.grecaptcha-badge {
  display: none !important;
}
